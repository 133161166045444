import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})

export class ContactComponent implements OnInit {


  contactForm= new FormGroup({
    email: new FormControl('',[Validators.required,Validators.email]),
    domain: new FormControl('',[Validators.required,Validators.pattern('^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}$')]),
    phone: new FormControl('',[Validators.required,Validators.minLength(5),Validators.maxLength(10),Validators.pattern('^[0-9]+$')]),
    message: new FormControl('',[Validators.required])
  })

  constructor(builder: ReactiveFormsModule, private contactService: ContactService) { }

  payload: any;
  $formSubmitted=false

  submit() {
  this.payload = {
      FirstName: this.contactForm.value.email,
      LastName: 'Zonemaster contact form',
      Company: this.contactForm.value.domain
    }
    this.contactService.createSfLead(this.payload);
    this.$formSubmitted=true
    console.log(this.$formSubmitted);
    
    this.contactForm.reset()
  }

  get email() {return this.contactForm.get('email');}
  get phone() {return this.contactForm.get('phone');}
  get domain() {return this.contactForm.get('domain');}

  ngOnInit(){
    
  }

}