import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  createSfLead(payload) { 

    return new Promise((resolve, reject) => {
      this.http.post('https://zonemaster.excedonet.net/server', payload)
        .subscribe(res => {
          if ('result' in res) {
            resolve(res['result']);
          }
        }, (err) => {
          console.error(err);
          reject(err);
        });
    });
  }
}